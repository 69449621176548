import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import website from "../website/data";
import "./Products.css";

const Products = ({ title, homeLabel }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("ALL");

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategory = (filter, maxItems) => {
    const filteredProducts = products?.filter((item) => {
      if (filter === "ALL") {
        return true;
      } else {
        return item?.category?.includes(filter);
      }
    });

    if (filteredProducts && filteredProducts.length > 0) {
      return (
        <div className="popular-games">
          <div className="games-list">
            {filteredProducts.slice(0, maxItems).map((item, index) => (
              <div
                key={index}
                className="game"
                onClick={() => navigate(`/product/${item?.name}`)}
              >
                <img src={`${website?.link}/${item?.image}`} alt="pro-img" />
                {item?.hotTag && <span className="hottag">{item?.hotTag}</span>}
                <h5>{item?.name}</h5>
                {item?.stock === "No" && (
                  <span className="outofstock">Out of stock</span>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="products-container">
      <div className="explore-products-container">
        <div className="filter-btns">
          <button
            onClick={() => setFilter("ALL")}
            className={`${filter === "ALL" && "active"}`}
          >
            ALL
          </button>
          {products?.filter((item) => item.category.includes("Mobile Legends"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Mobile Legends")}
              className={`${filter === "Mobile Legends" && "active"}`}
            >
              Mobile Legends
            </button>
          )}
          {products?.filter((item) => item.category.includes("Popular"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Popular")}
              className={`${filter === "Popular" && "active"}`}
            >
              Popular
            </button>
          )}
          {products?.filter((item) => item.category.includes("Vouchers"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Vouchers")}
              className={`${filter === "Vouchers" && "active"}`}
            >
              Vouchers
            </button>
          )}
          {products?.filter((item) =>
            item.category.includes("Premium Accounts")
          ).length > 0 && (
            <button
              onClick={() => setFilter("Premium Accounts")}
              className={`${filter === "Premium Accounts" && "active"}`}
            >
              Premium Accounts
            </button>
          )}
          {products?.filter((item) => item.category.includes("Social Media"))
            .length > 0 && (
            <button
              onClick={() => setFilter("Social Media")}
              className={`${filter === "Social Media" && "active"}`}
            >
              Social Media
            </button>
          )}
          {products?.filter((item) => item.category.includes("Other")).length >
            0 && (
            <button
              onClick={() => setFilter("Other")}
              className={`${filter === "Other" && "active"}`}
            >
              Other
            </button>
          )}
        </div>

        {renderCategory(filter, 100)}
      </div>
    </div>
  );
};

export default Products;
