import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { message } from "antd";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HomeIcon from "@mui/icons-material/Home";
import GamepadIcon from "@mui/icons-material/Gamepad";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import IMAGES from "../../img/image";
import "./SideMenu.css";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <div className="iconandimg">
          <HighlightOffIcon
            onClick={() => setSideMenu(!sideMenu)}
            className="close-icon"
          />
          <div className="logo">
            <img src={IMAGES.logo} alt="" />
          </div>
          <hr className="text-white mb-4" />
        </div>

        <ul className="p-0">
          <li
            className={`${location.pathname === "/" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <HomeIcon className="icon" />
            <Link to="/">Home</Link>
          </li>
          {user && (
            <li
              className={`${
                location.pathname === "/user-dashboard" && "active"
              }`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <DashboardIcon className="icon" />
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/my-account" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <AccountCircleIcon className="icon" />
              <Link to="/my-account">Account</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/orders" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <LocalMallIcon className="icon" />
              <Link to="/orders">Orders</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/query" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <HelpIcon className="icon" />
              <Link to="/query">Queries</Link>
            </li>
          )}
          {user && (
            <li
              className={`${location.pathname === "/wallet" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <AccountBalanceWalletIcon className="icon" />
              <Link to="/wallet">Wallet</Link>
            </li>
          )}
          {/* <li
            className={`${location.pathname === "/games" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <GamepadIcon className="icon" />
            <Link onClick={() => setSideMenu(!sideMenu)} to="/games">
              Games
            </Link>
          </li> */}
          <li
            className={`${location.pathname === "/support" && "active"}`}
            onClick={() => setSideMenu(!sideMenu)}
          >
            <SupportAgentIcon className="icon" />
            <Link onClick={() => setSideMenu(!sideMenu)} to="/support">
              Support
            </Link>
          </li>
          <hr className="text-white" />
          {!user && (
            <li
              className={`${location.pathname === "/login" && "active"}`}
              onClick={() => setSideMenu(!sideMenu)}
            >
              <LoginIcon className="icon" />
              <Link onClick={() => setSideMenu(!sideMenu)} to="/login">
                Login
              </Link>
            </li>
          )}
          {user && (
            <li
              onClick={() => {
                setSideMenu(!sideMenu);
                handleLogout();
              }}
            >
              <LogoutIcon className="icon" />
              <Link>Logout</Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
