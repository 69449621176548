import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminPaymentSettings.css";

const AdminWalletPaymentSettings = () => {
  const [paymentMode, setPaymentMode] = useState(null);
  const [desc, setDesc] = useState("");
  const [image, setImage] = useState(null);
  const [img, setImg] = useState(null);

  async function handleSubmit() {
    try {
      if (paymentMode === "") {
        return message.error("Select Mode");
      }
      const formData = new FormData();
      formData.append("paymentMode", paymentMode);
      formData.append("desc", desc);
      formData.append("image", image);

      const res = await axios.post("/api/walletMode/set-mode", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        getPaymentMode();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPaymentMode() {
    try {
      const res = await axios.get("/api/walletMode/get-mode", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPaymentMode(res.data.data.paymentMode);
        setDesc(res.data.data.desc);
        setImg(res.data.data.image);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPaymentMode();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users admin-add-money">
        <div className="page-title">
          <h3 className="m-0">Wallet Payment Mode</h3>
        </div>
        <hr />
        <div className="table-container">
          <div className="form-fields mb-2">
            <label className="text-dark">
              <small>Select Payment Mode</small>
            </label>
            <select
              name="paymentMode"
              className="form-select"
              onChange={(e) => setPaymentMode(e.target.value)}
              value={paymentMode}
            >
              <option value="">Select Payment Mode</option>
              <option value="onegateway">OneGateway</option>
              <option value="barcode">Barcode</option>
              {/* <option value="pgateway">Pgateway</option> */}
            </select>
          </div>

          {paymentMode === "barcode" && <img src={img} width="60px" alt="" />}
          {paymentMode === "barcode" && (
            <>
              <div className="form-fields mb-3">
                <label className="text-dark">
                  <small>Barcode</small>
                </label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
              <div className="form-fields mb-3">
                <label className="text-dark">
                  <small>Description</small>
                </label>
                <textarea
                  name="desc"
                  className="form-control"
                  placeholder="Enter description"
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                ></textarea>
              </div>
            </>
          )}
          <button onClick={handleSubmit} className="btn btn-dark p-2 px-3">
            Submit
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminWalletPaymentSettings;
