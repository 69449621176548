import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import axios from "axios";
import "./Account.css";

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState(null);

  const handleUpdate = async (e) => {
    if (form?.fname?.length < 5) {
      return message.error("Name should be more than 5 characters");
    }
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/updateprofile", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setForm(res.data.data.user);
        } else {
          localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="user-accout-details" style={{ minHeight: "300px" }}>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-12 col-lg-6">
              <div className="col-12">
                <div
                  className={`form-fields mb-3 ${
                    user?.fname === null && "warning"
                  }`}
                >
                  <label htmlFor="" className="form-label text-white">
                    Name {!user?.fname && "(Min 5 Characters)"}
                  </label>
                  {!user?.fname && (
                    <input
                      name="fname"
                      type="text"
                      placeholder="Enter your name"
                      className="form-control"
                      onChange={(e) =>
                        setForm({ ...form, fname: e.target.value })
                      }
                    />
                  )}
                  {user?.fname && <h6 className="text-white">{form?.fname}</h6>}
                </div>
              </div>
              <div className="col-12">
                <div
                  className={`form-fields mb-3 ${
                    user?.fname === null && "warning"
                  }`}
                >
                  <label htmlFor="" className="form-label text-white">
                    Email
                  </label>
                  {!user?.email && (
                    <input
                      type="text"
                      placeholder="Enter email"
                      className="form-control"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  )}
                  {user?.email && <h6 className="text-white">{form?.email}</h6>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Mobile
                  </label>
                  <h6 className="text-white">{form?.mobile}</h6>
                </div>
              </div>
              {/* <div className="col-12">
                <div className="form-fields mb-3">
                  <label htmlFor="" className="form-label text-white">
                    Password
                  </label>
                  <input
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                    type="text"
                    className="form-control"
                  />
                </div>
              </div> */}
              {(!user?.email || !user?.fname) && (
                <button
                  onClick={handleUpdate}
                  className="text-white w-100 updateprofilebtn"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Account;
