import React from "react";
import "./ReferAndEarn.css";
import Layout from "../components/Layout/Layout";

const ReferAndEarn = () => {
  return (
    <Layout>
      <div className="refer-and-earn-container">
        <p>Coming Soon</p>
      </div>
    </Layout>
  );
};

export default ReferAndEarn;
