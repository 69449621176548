import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
// import About from "./pages/About";
import Contact from "./pages/Contact";
import Leaderboard from "./pages/Leaderboard.js";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./admin/AdminDashboard";
import AdminUsers from "./admin/AdminUsers";
import EditUser from "./admin/EditUser";
import AdminPayments from "./admin/AdminPayments";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import AdminLogin from "./admin/AdminLogin";
import GamePage from "./pages/GamePage";
import Search from "./pages/Search";
import AdminProduct from "./admin/AdminProduct";
import AdminAddProduct from "./admin/AdminAddProduct";
import AdminEditProduct from "./admin/AdminEditProduct";
import AdminOrder from "./admin/AdminOrder";
import ProductInfo from "./pages/ProductInfo";
import Loader from "./pages/Loader";
import Support from "./pages/Support.js";
// import Cart from "./pages/Cart";
// import Checkout from "./pages/Checkout";
import PromoEvents from "./pages/PromoEvents.js";
import SinglePromoEvent from "./pages/SinglePromoEvent.js";
import TrackOrder from "./pages/TrackOrder";
import Orders from "./user/Orders";
import Account from "./user/Account";
import ViewOrder from "./user/ViewOrder";
import AdminViewOrder from "./admin/AdminViewOrder";
import AdminAddCoupon from "./admin/AdminAddCoupon.js";
import AdminQueries from "./admin/AdminQueries";
import Wallet from "./user/Wallet";
import Query from "./user/Query";
import Maintenance from "./user/Maintenance";
import Announcement from "./user/Announcement.js";
import ReferAndEarn from "./user/ReferAndEarn.js";
import AdminBarcodePayments from "./admin/AdminBarcodePayments";
import { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import AdminGallery from "./admin/AdminGallery";
import AdminNotification from "./admin/AdminNotification";
import AdminBanners from "./admin/AdminBanners.js";
import AdminPromoEvents from "./admin/AdminPromoEvents.js";
import AdminAddMoney from "./admin/AdminAddMoney.js";
import AdminPaymentSettings from "./admin/AdminPaymentSettings.js";
import AdminWalletPaymentSettings from "./admin/AdminWalletPaymentSettings.js";
import AdminWalletBarcodeAddMoney from "./admin/AdminWalletBarcodeAddMoney.js";
import AdminWalletDiscount from "./admin/AdminWalletDiscount.js";
import AdminBarcodeOrders from "./admin/AdminBarcodeOrders.js";
import AdminAddReward from "./admin/AdminAddReward.js";
import PaymentHistory from "./admin/PaymentHistory.js";

function App() {
  const [website, setWebsite] = useState(true);
  const [loading, setLoading] = useState(false);

  async function getWebsite() {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setWebsite(res.data.data.website);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getWebsite();
  }, []);

  return (
    <BrowserRouter>
      {loading ? (
        <Loader />
      ) : website ? (
        <Routes>
          {/* pages */}
          <Route path="/:token?" element={<Home />} />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* <Route path="/games" element={<GamePage />} /> */}
          <Route path="/search" element={<Search />} />
          <Route path="/support" element={<Contact />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/product/:name?" element={<ProductInfo />} />
          <Route path="/promo/:id?" element={<SinglePromoEvent />} />
          <Route path="/promo" element={<PromoEvents />} />
          <Route path="/announcement" element={<Announcement />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/cart" element={<Cart />} /> */}
          {/* <Route path="/checkout" element={<Checkout />} /> */}
          <Route
            path="/orders"
            element={
              <ProtectedRoute>
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payments"
            element={
              <ProtectedRoute>
                <PaymentHistory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wallet"
            element={
              <ProtectedRoute>
                <Wallet />
              </ProtectedRoute>
            }
          />
          <Route
            path="/query"
            element={
              <ProtectedRoute>
                <Query />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="/view-order/:orderId?"
            element={
              <ProtectedRoute>
                <ViewOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/refer-and-earn"
            element={
              <ProtectedRoute>
                <ReferAndEarn />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/service" element={<Service />} /> */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          {/* ======================== USER PAGES =============================== */}
          {/* ========== EMAIL VERIFY */}
          <Route
            path="/user-dashboard/:token?"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          {/* ======================== USER PAGES =============================== */}
          {/* ======================== ADMIN PAGES =============================== */}
          <Route
            path="/admin-login"
            element={
              <PublicRoute>
                <AdminLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-orders"
            element={
              <ProtectedRoute>
                <AdminOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-barcode-orders"
            element={
              <ProtectedRoute>
                <AdminBarcodeOrders />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-reward"
            element={
              <ProtectedRoute>
                <AdminAddReward />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-view-order/:orderId?"
            element={
              <ProtectedRoute>
                <AdminViewOrder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-products"
            element={
              <ProtectedRoute>
                <AdminProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-product"
            element={
              <ProtectedRoute>
                <AdminAddProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-product/:id?"
            element={
              <ProtectedRoute>
                <AdminEditProduct />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-users"
            element={
              <ProtectedRoute>
                <AdminUsers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-edit-user/:id?"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-money"
            element={
              <ProtectedRoute>
                <AdminAddMoney />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-payments"
            element={
              <ProtectedRoute>
                <AdminPayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-payment"
            element={
              <ProtectedRoute>
                <AdminBarcodePayments />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-queries"
            element={
              <ProtectedRoute>
                <AdminQueries />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-gallery"
            element={
              <ProtectedRoute>
                <AdminGallery />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-notification"
            element={
              <ProtectedRoute>
                <AdminNotification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-banners"
            element={
              <ProtectedRoute>
                <AdminBanners />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-promo"
            element={
              <ProtectedRoute>
                <AdminPromoEvents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-add-coupon"
            element={
              <ProtectedRoute>
                <AdminAddCoupon />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-settings"
            element={
              <ProtectedRoute>
                <AdminPaymentSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-wallet-settings"
            element={
              <ProtectedRoute>
                <AdminWalletPaymentSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-barcode-add-money"
            element={
              <ProtectedRoute>
                <AdminWalletBarcodeAddMoney />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-wallet-discount"
            element={
              <ProtectedRoute>
                <AdminWalletDiscount />
              </ProtectedRoute>
            }
          />
          {/* ======================== ADMIN PAGES =============================== */}
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Maintenance />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-login" element={<Login />} />
          <Route path="*" element={<Maintenance />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default App;
