import React, { useEffect, useState } from "react";
import { message, Pagination } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import AdminLayout from "./components/AdminLayout";
import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import "./AdminBarcodeOrders.css";

const AdminWalletBarcodeAddMoney = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [allUser, setAllUser] = useState(null);
  const [originalUserData, setOriginalUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [utr, setUtr] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  function handleClearFilter() {
    setUtr("");
    getAllOrders();
  }

  const getAllOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/wallet/getbarcode", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllUser(res.data.data);
        setOriginalUserData(res.data.data.reverse());
        setTotalCount(res.data.data.length);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (originalUserData) {
      const filteredUsers = originalUserData.filter((order) => {
        const utrMatch = order.utr && order.utr.includes(utr);
        return utrMatch;
      });
      setAllUser(filteredUsers);
      setTotalCount(filteredUsers.length);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [utr]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1); // Reset to first page when changing page size
    setPageSize(size);
  };

  const displayedUsers = allUser?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  async function handleVerify(utr, email, orderId) {
    if (!utr || !email || !orderId) {
      return message.error("Failed to udpdate");
    }
    try {
      const res = await axios.post(
        "/api/wallet/verify",
        { utr: utr, email: email, orderId: orderId },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getAllOrders();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleReject(orderId) {
    try {
      const response = await axios.post(
        "/api/admin/rejectOrder",
        {
          orderId: orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success) {
        message.success(response.data.message);
        getAllOrders();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <AdminLayout>
      <div className="admin-users-container admin-barcode-container">
        <div className="page-title">
          <h3 className="m-0">Orders</h3>
          <h6>Total Orders - {totalCount}</h6>
        </div>
        <hr />
        <div className="table-container">
          <div className="tools mb-5">
            <div className="form-fields">
              <input
                className="form-control"
                type="search"
                name="utr"
                placeholder="Search by UTR"
                value={utr}
                onChange={(e) => setUtr(e.target.value)}
              />
            </div>

            <button
              className="bg-danger px-3"
              onClick={() => handleClearFilter()}
            >
              Clear Filter
            </button>
          </div>
          {loading ? (
            <div class="loader">
              <span class="loader-text">loading</span>
              <span class="load"></span>
            </div>
          ) : (
            <>
              <table className="table user-table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Amount</th>
                    <th>UTR</th>
                    <th>Status</th>
                    <th>Date & Time</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedUsers &&
                    displayedUsers.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <small>{user?.orderId}</small>
                          </td>
                          <td>
                            <small>{user?.email}</small>
                          </td>
                          <td>
                            <small>{user?.mobile}</small>
                          </td>
                          <td>
                            <small>{user?.amount}</small>
                          </td>
                          <td>
                            <small>{user?.utr}</small>
                          </td>
                          <td>
                            <small>{user?.status}</small>
                          </td>
                          <td>
                            <small>
                              {new Date(user?.createdAt).toLocaleString(
                                "default",
                                {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                }
                              )}
                            </small>
                          </td>
                          <td align="center">
                            {user?.status === "pending" ? (
                              <div className="verify">
                                <button
                                  onClick={(e) =>
                                    handleVerify(
                                      user?.utr,
                                      user?.email,
                                      user?.orderId
                                    )
                                  }
                                  className="p-1 btn btn-success me-1"
                                >
                                  Verify
                                </button>
                                <button
                                  onClick={(e) => handleReject(user?.utr)}
                                  className="p-1 btn btn-danger"
                                >
                                  Reject
                                </button>
                              </div>
                            ) : (
                              <span>
                                <small>{user?.status}</small>
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Pagination
                className="my-3"
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                onChange={handlePageChange}
                showSizeChanger
                onShowSizeChange={handlePageSizeChange}
              />
            </>
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminWalletBarcodeAddMoney;
