import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import HomeIcon from "@mui/icons-material/Home";
import PercentIcon from "@mui/icons-material/Percent";
import GamepadIcon from "@mui/icons-material/Gamepad";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WalletIcon from "@mui/icons-material/Wallet";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import CancelIcon from "@mui/icons-material/Cancel";
import TollIcon from "@mui/icons-material/Toll";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LoginIcon from "@mui/icons-material/Login";
import "./StickyFooter.css";

const StickyFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [menu, setMenu] = useState(false);

  function handleMenu() {
    setMenu(!menu);
  }

  function handleClose(e) {
    e.stopPropagation();
    setMenu(!menu);
  }

  function handleLogout() {
    dispatch(setUser(null));
    localStorage.removeItem("token");
    navigate("/");
  }

  return (
    <div className="sticky-footer d-block d-lg-none">
      <div className="footer-tabs">
        <div
          onClick={() => navigate("/")}
          className={`footer-icon ${location.pathname === "/" && "active"}`}
        >
          <HomeIcon className="icon" />
        </div>
        <div
          onClick={() => navigate("/support")}
          className={`footer-icon ${
            location.pathname === "/support" && "active"
          }`}
        >
          <SupportAgentIcon className="icon" />
        </div>
        {user && (
          <div
            onClick={() => navigate("/user-dashboard")}
            className={`footer-icon ${
              location.pathname === "/user-dashboard" && "active"
            }`}
          >
            <DashboardIcon className="icon" />
          </div>
        )}
        <div
          onClick={() => navigate("/announcement")}
          className={`footer-icon ${
            location.pathname === "/announcement" && "active"
          }`}
        >
          <NotificationsActiveIcon className="icon" />
        </div>
        {!user && (
          <div
            onClick={() => navigate("/login")}
            className={`footer-icon ${
              location.pathname === "/login" && "active"
            }`}
          >
            <LoginIcon className="icon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StickyFooter;
