import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice.js";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import axios from "axios";
import getUserData from "../utils/userDataService.js";
import TollIcon from "@mui/icons-material/Toll";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import website from "../website/data.js";
import "./ProductInfo.css";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("WALLET");
  const [payment, setPayment] = useState("WALLET");
  const [paymentOptions, setPaymentOptions] = useState("");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  const [prodId, setProdId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [couponApplied, setCouponApplied] = useState(null);
  const [discount, setDiscount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [couponName, setCouponName] = useState("");
  const [originalPrice, setOriginalPrice] = useState(null);
  // payment mode
  const [paymentMode, setPaymentMode] = useState("");
  const [desc, setDesc] = useState("");
  const [img, setImg] = useState("");
  const [utr, setUtr] = useState("");
  const [walletDiscount, setWalletDiscount] = useState("");

  async function getWalletDiscount() {
    try {
      const res = await axios.get("/api/wallet/getdiscount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setWalletDiscount(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getWalletDiscount();
  }, []);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    getAllCoupons();
  }, []);

  const getAllCoupons = async () => {
    try {
      const res = await axios.get("/api/admin/get-coupons");
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Payment mode
  async function getPaymentMode() {
    try {
      const res = await axios.get("/api/paymentmode/get-mode", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPaymentMode(res.data.data.paymentMode);
        setDesc(res.data.data.desc);
        setImg(res.data.data.image);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPaymentMode();
  }, []);

  // ==== COUPON
  function applyCoupon(e) {
    e.preventDefault();
    if (data?.length === 0 || data === null) {
      return message.error("No Coupon Found");
    }
    if (coupon === "") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1500);
      return;
    }
    // find coupon
    const couponCode = data && data?.find((item) => item.name === coupon);
    setCouponName(couponCode?.name);

    if (!couponCode) {
      return message.error("No Coupon Found");
    }

    if (couponCode?.minValue >= selectedPrice) {
      message.error(`Minimum order value is: ${couponCode?.minValue}`);
      return;
    }
    //
    if (coupon) {
      setCouponApplied(true);
      setDiscount(couponCode?.discount);
      setFinalAmount(selectedPrice - couponCode?.discount);
      message.success("Coupon applied success");
    } else {
      message.error("No coupon found");
    }
  }
  const removeDiscount = () => {
    setCouponApplied(false);
    setFinalAmount((prev) => prev + discount);
  };
  // ==== COUPON ENDS

  // GETTING PRICE
  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      // buying price
      const ogPrice = product?.cost?.find(
        (item) => item.amount === amount
      )?.buyingprice;
      setOriginalPrice(ogPrice);
      if (couponApplied) {
        setCouponApplied(false);
      }
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      // buying price
      const ogPrice = product?.cost?.find(
        (item) => item.amount === amount
      )?.buyingprice;
      setOriginalPrice(ogPrice);
      if (couponApplied) {
        setCouponApplied(false);
      }
    }
    const prodId = product?.cost?.find(
      (item) => item.amount === amount
    )?.prodId;
    setProdId(prodId);
  }

  // GET PRODUCT DETAILS
  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultProdId = res.data.data?.cost?.[0]?.prodId;
        const defaultPrice =
          user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultOriginalPrice = res.data.data?.cost?.[0]?.buyingprice;

        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setFinalAmount(defaultPrice);
        setOriginalPrice(defaultOriginalPrice);
        setProductId(defaultId);
        setProdId(defaultProdId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  // GENERATING ORDERID
  const generateOrderId = () => {
    const numbers = "01234567"; // 8 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
    const year = String(now.getFullYear()).slice(2); // last two digits of the year
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const orderId = `${year}${month}${day}${seconds}${randomNumbers.join("")}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  // PLAYER VALIDATIION
  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter User ID" : "Enter (   Zone ID   )"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.apiName === "smileOne" ? product?.region : "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: product?.apiName === "smileOne" ? idPro : "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  // CHECKING ORDER PLACEMENT
  function checkPlaceOrder(e) {
    if (product.fields === "2") {
      if (userId === "") {
        return message.error(`Enter ${product?.tagOne}`);
      }
      if (zoneId === "") {
        return message.error(`Enter ${product?.tagTwo}`);
      }
    }
    if (product.fields === "1") {
      if (userId === "") {
        return message.error(`Enter ${product?.tagOne}`);
      }
    }
    if (product?.playerCheckBtn === "yes") {
      if (playerCheck === null) {
        return message.error(`Check your playername`);
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          if (paymentMode === "onegateway") {
            handleSmileOneOnegatewayOrder(e);
          } else {
            handleSmileOneBarcodeOrder(e);
          }
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          if (paymentMode === "onegateway") {
            handleMoogoldOnegatewayOrder(e);
          } else {
            handleMoogoldBarcodeOrder(e);
          }
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        if (paymentMode === "onegateway") {
          handleManualOnegatewayOrder(e);
        } else {
          handleManualBarcodeOrder(e);
        }
      } else {
        handleManaulWalletOrder(e);
      }
    }
  }

  // smile
  const handleSmileOneOnegatewayOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        orderId: orderId,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        prodId: prodId,
        productName: product?.name,
        paymentNote: product?.region,
        discount: discount,
      };
      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneBarcodeOrder = async (e) => {
    e.preventDefault();
    if (!utr) {
      return message.error("Enter UTR");
    }
    try {
      const paymentObject = {
        api: "yes",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: finalAmount,
        originalPrice: originalPrice,
        apiName: "smileOne",
        region: product?.region,
        productId: productId,
        discount: discount,
        utr: utr,
      };

      const res = await axios.post("/api/order/smile", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        navigate("/user-dashboard");
        message.success("Order place successfully");
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(finalAmount)) {
      return message.error("Balance is less for this order");
    }
    try {
      e.preventDefault();
      setLoading(true);
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        prodId: prodId,
        region: product.region,
        customerEmail: user?.email,
        customerMobile: user?.mobile,
        productName: product?.name,
        discount: discount,
      };

      // setLoading(true);
      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
        setLoading(false);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      navigate("/failure");
    }
  };

  // moogold
  async function handleMoogoldOnegatewayOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        orderId: orderId,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        prodId: prodId,
        productName: product?.name,
        paymentNote: product?.gameName,
        discount: discount,
      };

      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldBarcodeOrder(e) {
    e.preventDefault();
    if (!utr) {
      return message.error("Enter UTR");
    }
    try {
      const paymentObject = {
        api: "yes",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: finalAmount,
        originalPrice: originalPrice,
        apiName: "moogold",
        productId: productId,
        gameId: product?.gameName,
        utr: utr,
        discount: discount,
      };

      const res = await axios.post("/api/order/moogold", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        navigate("/user-dashboard");
        message.success("Order place successfully");
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    if (parseInt(balance) < parseInt(finalAmount)) {
      return message.error("Balance is less for this order");
    }
    setLoading(true);
    e.preventDefault();
    try {
      const orderObject = {
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        prodId: prodId,
        gameName: product.gameName,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        productName: product?.name,
        discount: discount,
      };

      // setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
        setLoading(false);
        navigate("/success");
      } else {
        setLoading(false);
        message.error(res.data.message);
        navigate("/failure");
      }
    } catch (error) {
      setLoading(false);
      setOrderSuccess(false);
      console.log(error);
    }
  }

  // manual
  const handleManualOnegatewayOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        orderId: orderId,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        productName: product?.name,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        discount: discount,
        paymentNote: "Manaul order",
        prodId: prodId,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl;
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleManualBarcodeOrder = async (e) => {
    e.preventDefault();
    if (!utr) {
      return message.error("Enter UTR");
    }
    try {
      const paymentObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        zoneid: zoneId,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        prodId: prodId,
        discount: discount,
        utr: utr,
      };

      const res = await axios.post("/api/order/manual", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        navigate("/user-dashboard");
        message.success("Order placed successfully");
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleManaulWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        userid: userId,
        zoneid: zoneId,
        pname: product?.name,
        prodId: prodId,
        discount: discount,
      };

      setLoading(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        setOrderSuccess(true);
        message.success(res.data.message);
        navigate("/success");
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Layout>
      {loading ? (
        <div className="loading-container">
          <div className="spinner">
            <div className="spinner1"></div>
          </div>
          <h4>Processing..</h4>
        </div>
      ) : (
        <div className="product-info-container">
          <div className="pic">
            <div className="product-info-img bg-fields">
              <div className="game-name">
                <img src={`${website.link}/${product?.image}`} alt="" />
              </div>
              <hr className="text-white d-none d-lg-block" />
              <p className="m-0">
                <h4>{product?.name}</h4>
              </p>
              <p>
                <small>{product?.desc}</small>
              </p>
            </div>
          </div>

          <div className="product-info-content mb-2">
            {/* ====================== FIELDS ===============  */}
            {/* ====================== FIELDS ===============  */}
            <div className="bg-fields">
              <h5>Enter IDs</h5>
              {product?.fields === "1" ? (
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder={product?.tagOne}
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
              ) : product?.fields === "2" ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder={product?.tagOne}
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder={product?.tagTwo}
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                </>
              ) : (
                product?.fields === "3" && (
                  <>
                    <div className="d-flex align-items-center">
                      <input
                        className="player-tag"
                        type="text"
                        name="userId"
                        placeholder={`${product?.tagOne}`}
                        onChange={(e) => setUserId(e.target.value)}
                        value={userId}
                      />
                    </div>
                    <select
                      name="zoneId"
                      className="form-select player-tag"
                      onChange={(e) => setZoneId(e.target.value)}
                    >
                      <option value="">Select Server</option>
                      {product?.tagTwo?.split("+")?.map((item, index) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </select>
                    {loading && (
                      <>
                        <div
                          className="spinner-border spinner-border-sm me-2 mt-2"
                          role="status"
                        >
                          <span className="visually-hidden"></span>
                        </div>
                        Checking Username
                      </>
                    )}
                    <div>
                      <span className="text-success">
                        {playerCheck && "Username: " + playerCheck}
                      </span>
                    </div>
                  </>
                )
              )}
              {product?.playerCheckBtn === "yes" && (
                <button className="buy-now" onClick={handleCheckPlayer}>
                  Check Username
                  {loading && (
                    <div className="spinner-grow spinner-grow-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              )}
              {playerCheck !== null && (
                <p className="playername text-success">
                  {playerCheck && "Username: " + playerCheck}
                </p>
              )}
            </div>
            {/* ====================== PACKAGE ===============  */}
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields">
              <h5>Select Package</h5>
              <div className="p-amount">
                {product?.cost?.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        setAmount(item.amount);
                        setPriceAndId(item.amount);
                      }}
                      key={index}
                      className={`amount ${
                        amount === item?.amount && "active"
                      }`}
                    >
                      <div className="image">
                        <img src={item?.pimg} alt="" />{" "}
                      </div>
                      <div className="pack">
                        <p>₹{item.price}</p>
                        <span>₹{item.fakePrice}</span>
                      </div>
                      <div className="packdata">
                        <span>{item.packData}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* ====================== COUPON ===============  */}
            {/* ====================== COUPON ===============  */}
            {/* <div className="bg-fields">
              {couponApplied ? (
                <>
                  <h5>Discount Applied</h5>
                  <div className="coupon-tag">
                    <p className="m-0">
                      {couponName} <CheckCircleOutlineIcon className="icon" />
                    </p>
                    <button className="remove-coupon" onClick={removeDiscount}>
                      Remove
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Apply Coupon</h5>
                  <div className="coupon-box">
                    <input
                      className="player-tag m-0 coupon-input"
                      type="text"
                      name="coupon"
                      placeholder="Enter Coupon"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                    <button onClick={applyCoupon}>Apply</button>
                  </div>
                </>
              )}
              {error && coupon === "" && (
                <span className="text-danger">Enter valid coupon</span>
              )}
            </div> */}
            {/* ====================== PAYMENT METHOD ===============  */}
            {/* ====================== PAYMENT METHOD ===============  */}
            <div className="bg-fields">
              <h5>Choose the payment method</h5>
              <div className="payment-container">
                <div
                  onClick={() => {
                    setMode("WALLET");
                    setPayment("WALLET");
                  }}
                  className={`payment wallet ${mode === "WALLET" && "active"}`}
                >
                  {walletDiscount?.status && (
                    <p className="m-0 text-end discount">
                      {walletDiscount.discount}% OFF
                    </p>
                  )}
                  <div className="d-flex align-items-center gap-1">
                    <TollIcon className="icon" />
                    <span>T Coins</span>
                  </div>
                  {walletDiscount?.status ? (
                    <span>
                      ₹
                      {selectedPrice -
                        (selectedPrice * walletDiscount.discount) / 100}
                    </span>
                  ) : (
                    <span>₹{selectedPrice}</span>
                  )}
                </div>
                <div
                  onClick={() => {
                    setMode("UPI");
                    setPayment("UPI");
                  }}
                  className={`payment upi ${payment === "UPI" && "active"}`}
                >
                  <div>
                    <img src={IMAGES.upi} alt="" />
                  </div>
                  <div>₹{selectedPrice}</div>
                </div>
                <div className="barcode-image">
                  {mode === "UPI" && paymentMode === "barcode" && (
                    <>
                      <img src={img} alt="" />
                      <div className="utrinput">
                        <label className="text-white">Transaction Id</label>
                        <input
                          placeholder="Enter transaction Id"
                          className="form-control"
                          onChange={(e) => setUtr(e.target.value)}
                          value={utr}
                          type="text"
                        />
                      </div>
                      <button className="buy-now" onClick={checkPlaceOrder}>
                        Submit
                      </button>
                      <p>{desc}</p>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* ====================== BUY NOW BUTTON ===============  */}
            {/* ====================== BUY NOW BUTTON ===============  */}
            {(paymentMode !== "barcode" || mode === "WALLET") && (
              <div className="bg-fields">
                <div className="total-value">
                  <h5>Buy Now</h5>
                  <div className="text-end">
                    <p className="m-0">
                      <b>
                        ₹
                        {mode === "WALLET"
                          ? selectedPrice -
                            (selectedPrice * walletDiscount.discount) / 100
                          : finalAmount}
                      </b>
                    </p>
                    <span>
                      <small>
                        Amount {amount} |{" "}
                        <span>
                          Using -{" "}
                          {paymentOptions !== "" ? paymentOptions : mode}
                        </span>
                      </small>
                    </span>
                  </div>
                </div>
                <div className="buy-btn-container">
                  {user?.block === "yes" || product?.stock === "no" ? (
                    <button className="buy-now" style={{ opacity: "0.7" }}>
                      Out of Stock
                    </button>
                  ) : !user ? (
                    <button
                      onClick={() => navigate("/login")}
                      className="buy-now"
                    >
                      Please Login First
                    </button>
                  ) : (
                    <button onClick={checkPlaceOrder} className="buy-now">
                      BUY NOW
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
