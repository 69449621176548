import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminAddMoney.css";

const AdminAddMoney = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState(null);
  const [form, setForm] = useState({
    orderId: "",
    email: "",
    mobile: "",
    amount: "",
    type: "",
    reason: "",
  });

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number

    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;

    setForm({ ...form, orderId: orderId });
  };

  useEffect(() => {
    generateOrderId();
  }, [form?.orderId === ""]);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit() {
    try {
      if (form?.orderId === "") {
        return;
      }
      const res = await axios.post("/api/admin/add-money", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        getAddMoneyHistory();
        message.success(res.data.message);
        setForm({
          email: "",
          mobile: "",
          amount: "",
          type: "",
          reason: "",
          orderId: "",
        });
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAddMoneyHistory() {
    try {
      const res = await axios.get("/api/wallet/addmoneyhistory", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setHistory(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAddMoneyHistory();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users admin-add-money">
        <div className="page-title">
          <h3 className="m-0">Add Money</h3>
        </div>
        <hr />
        <div className="table-container">
          <div className="form-fields mb-2">
            <input
              placeholder="Enter email"
              name="email"
              type="email"
              className="form-control"
              onChange={handleChange}
              value={form?.email}
            />
          </div>
          <div className="form-fields mb-2">
            <input
              placeholder="Enter mobile number"
              name="mobile"
              type="number"
              className="form-control"
              onChange={handleChange}
              value={form?.mobile}
            />
          </div>
          <div className="form-fields mb-2">
            <input
              placeholder="Enter amount"
              type="text"
              className="form-control"
              name="amount"
              onChange={handleChange}
              value={form?.amount}
            />
          </div>
          <div className="form-fields mb-2">
            <select
              name="type"
              className="form-select"
              onChange={handleChange}
              value={form?.type}
            >
              <option value="">Select Type</option>
              <option value="addmoney">Add Money</option>
              <option value="refund">Refund</option>
              <option value="reward">Reward</option>
            </select>
          </div>
          <div className="form-fields mb-2">
            <input
              placeholder="Enter reason"
              type="text"
              className="form-control"
              name="reason"
              onChange={handleChange}
              value={form?.reason}
            />
          </div>
          <button onClick={handleSubmit} className="btn btn-dark p-2 px-3">
            Submit
          </button>
        </div>
        <div className="addmoneylist">
          <table className="table">
            <thead>
              <tr>
                <th>Sr No</th>
                <th>Order ID</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Balance Before</th>
                <th>Balance After</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {history &&
                history?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <small>{index + 1}</small>
                      </td>
                      <td>
                        <small>{item?.orderId}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>{item?.amount}</small>
                      </td>
                      <td>
                        <small>{item?.balanceBefore}</small>
                      </td>
                      <td>
                        <small>{item?.balanceAfter}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString(
                            "default",
                            {}
                          )}
                        </small>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddMoney;
