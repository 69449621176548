import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import IMAGES from "../img/image";
import "./Leaderboard.css";

const Leaderboard = () => {
  const [data, setData] = useState(null);
  const [countdown, setCountdown] = useState("");
  const [tab, setTab] = useState(0);
  const [list, setList] = useState(null);
  const [rewardList, setRewardList] = useState(null);
  const [reward, setReward] = useState(null);
  const [showReward, setShowReward] = useState(false);
  const [user, setUser] = useState(null);
  const [position, setPosition] = useState(null);

  const getCurrentDateRange = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    let startDate, endDate;
    const currentDay = today.getDate();

    if (currentDay >= 1 && currentDay <= 7) {
      startDate = `${currentYear}-${currentMonth}-01`;
      endDate = `${currentYear}-${currentMonth}-07`;
    } else if (currentDay >= 8 && currentDay <= 14) {
      startDate = `${currentYear}-${currentMonth}-08`;
      endDate = `${currentYear}-${currentMonth}-14`;
    } else if (currentDay >= 15 && currentDay <= 21) {
      startDate = `${currentYear}-${currentMonth}-15`;
      endDate = `${currentYear}-${currentMonth}-21`;
    } else {
      const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();
      startDate = `${currentYear}-${currentMonth}-22`;
      endDate = `${currentYear}-${currentMonth}-${lastDayOfMonth}`;
    }
    return { startDate, endDate };
  };

  const getNextWeekStartDate = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;
    const currentDay = today.getDate();
    let nextWeekStart;

    if (currentDay >= 1 && currentDay <= 7) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 8);
    } else if (currentDay >= 8 && currentDay <= 14) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 15);
    } else if (currentDay >= 15 && currentDay <= 21) {
      nextWeekStart = new Date(currentYear, currentMonth - 1, 22);
    } else {
      // Move to the 1st of the next month
      const nextMonth = currentMonth;
      nextWeekStart = new Date(currentYear, nextMonth, 1);
    }

    // Set the time to midnight (start of the next week)
    nextWeekStart.setHours(0, 0, 0, 0);

    return nextWeekStart;
  };

  const calculateCountdown = () => {
    const nextWeekStart = getNextWeekStartDate();
    const now = new Date();
    const timeDiff = nextWeekStart - now;

    // Calculate the remaining hours, minutes, and seconds
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDiff / 1000) % 60);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const getLeaderboard = async () => {
    const { startDate, endDate } = getCurrentDateRange();

    try {
      const res = await axios.get(
        `/api/user/leaderboard?startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data.success) {
        const emails = res.data.data;
        const updatedEmails = emails.map((item) => {
          if (item?.fname?.includes("@gmail.com")) {
            return {
              ...item,
              fname: item.fname.replace("@gmail.com", ""),
            };
          }
          return item;
        });
        setData(updatedEmails);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getLeaderboardRewardList = async () => {
    try {
      const res = await axios.get(`/api/leaderboard/get-leaderboard-rewards`);
      if (res.data.success) {
        setList(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  async function getRewardList() {
    try {
      const res = await axios.get("/api/leaderboard/get-rewards", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setRewardList(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleCheckReward(index) {
    const position = rewardList.filter(
      (item) => item?.position == index + 1
    )[0];
    // If no position is found, set the last item in the list
    const rewardToSet = position ? position : rewardList[rewardList.length - 1];
    setReward(rewardToSet);
    setShowReward(!showReward);
  }

  useEffect(() => {
    getRewardList();
  }, []);

  useEffect(() => {
    getLeaderboard();
    const countdownInterval = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(countdownInterval); // Clear the interval on component unmount
  }, []);

  useEffect(() => {
    getLeaderboardRewardList();
  }, []);

  return (
    <Layout>
      {showReward && (
        <div className="showreward" onClick={() => setShowReward(!showReward)}>
          <div className="user-reward">
            <h5>{user?.fname}</h5>
            <h6>Total Spent: ₹{user?.totalSpent}</h6>
            <h6>Position: {position}</h6>
            <h6>Reward: ₹{reward?.reward}</h6>
            <p>
              <b>Note:</b> The reward will be given after the specified time
              ends, and the reward's position and price may change before the
              time runs out.
            </p>
          </div>
        </div>
      )}
      <div className="leaderboard-container">
        <h4 className="heading">The Leaderboard will reset in: {countdown}</h4>
        <div className="leaderboardbuttons">
          <button
            onClick={() => setTab(0)}
            className={`${tab === 0 && "active"}`}
          >
            Active Challenge
          </button>
          <button
            onClick={() => setTab(1)}
            className={`${tab === 1 && "active"}`}
          >
            Past Rewards
          </button>
        </div>
        {data && data?.length === 0 ? (
          <div className="norecord">
            <h5 className="m-0">NO RECORD</h5>
          </div>
        ) : tab === 0 ? (
          <div className="leaderboard-list">
            {data?.map((item, index) => {
              return (
                <div
                  className={`items ${index <= 2 && "winner"}`}
                  key={index}
                  onClick={() => {
                    handleCheckReward(index);
                    setUser(item);
                    setPosition(index + 1);
                  }}
                >
                  {index <= 2 && (
                    <img
                      className="ms-2 mb-2"
                      src={`${
                        index === 0
                          ? IMAGES.first
                          : index === 1
                          ? IMAGES.second
                          : IMAGES.third
                      }`}
                      alt=""
                    />
                  )}
                  <div>
                    <span className="me-1">
                      {index > 2 && <p>{index + 1 + ")"}</p>}
                    </span>
                    <p>{item?.fname}</p>
                  </div>
                  <span className="d-flex align-items-center">
                    ₹{item?.totalSpent}
                    <ExpandCircleDownIcon className="icon" />
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="leaderboard-rewards">
            {list?.winners?.map((item, index) => {
              return (
                <div className="winners-list">
                  <div className="winners">
                    <span className="me-2">{index + 1}</span>
                    {item.fname}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Leaderboard;
