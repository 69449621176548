import React, { useEffect, useState } from "react";
import { message } from "antd";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import "./AdminWalletDiscount.css";

const AdminWalletDiscount = () => {
  const [discount, setDiscount] = useState("");
  const [status, setStatus] = useState(null);

  async function handleSubmit() {
    if (isNaN(discount)) {
      return message.error("Please enter only number");
    }
    try {
      const res = await axios.post(
        "/api/wallet/discount",
        { discount: discount, status: status },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getWalletDiscount() {
    try {
      const res = await axios.get("/api/wallet/getdiscount", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setDiscount(res.data.data.discount);
        setStatus(res.data.data.status);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getWalletDiscount();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container admin-barcode-container">
        <div className="page-title">
          <h3 className="m-0">Wallet Discount</h3>
        </div>
        <hr />
        <div className="table-container">
          <div className="form-fields">
            <label className="text-dark form-label">
              Enter Discount (In % only)
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter discount"
              onChange={(e) => setDiscount(e.target.value)}
              value={discount}
            />
          </div>
          <div className="form-fields mt-3">
            <label className="form-label text-dark">Status</label>
            <select
              className="form-select"
              name="status"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value="">Select Status</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <button onClick={handleSubmit} className="btn btn-success mt-2">
            Submit
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminWalletDiscount;
