import React, { useEffect, useState } from "react";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import "./Announcement.css";

const Announcement = () => {
  const [ann, setAnn] = useState(null);

  async function getNoti() {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        setAnn(res.data.data[0]);
        // setImage(res.data.data[0].image);
        // setLink(res.data.data[0].link);
        // setDisplay(res.data.data[0].display);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getNoti();
  }, []);

  return (
    <Layout>
      <div className="announcement-container">
        <div className="anncontainer">
          <img src={ann?.image} alt="" />
          <p>{ann?.desc}</p>
        </div>
      </div>
    </Layout>
  );
};

export default Announcement;
