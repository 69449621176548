import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import CryptoJS from "crypto-js";
import "./Register.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState({});
  const [otp, setOtp] = useState(null);
  const [showOtpField, setShowOtpField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendMobileOtp = async (e) => {
    if (form?.mobile?.length !== 10) {
      return message.error("Enter 10 digits mobile number");
    }
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post("/api/user/sendotp", form);
      if (res.data.success) {
        message.success(res.data.message);
        setShowOtpField(true);
        const {
          otp: encryptedOTP,
          key: encryptedKey,
          iv: encryptedIv,
        } = res.data.data;

        const key = CryptoJS.enc.Hex.parse(encryptedKey);
        const iv = CryptoJS.enc.Hex.parse(encryptedIv);

        const decryptedOTP = CryptoJS.AES.decrypt(
          { ciphertext: CryptoJS.enc.Hex.parse(encryptedOTP) },
          key,
          { iv: iv }
        ).toString(CryptoJS.enc.Utf8);
        setOtp(decryptedOTP);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    if (form?.otp !== otp) {
      return message.error("Incorrect OTP");
    }
    e.preventDefault();
    if (form?.mobile === "7069451933" || form?.mobile === "9284263003") {
      try {
        const res = await axios.post("/api/user/admin", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          navigate("/admin-dashboard");
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axios.post("/api/user/login", form);
        if (res.data.success) {
          localStorage.setItem("token", res.data.token);
          if (res.data.userExist === "yes") {
            navigate("/");
          } else {
            setTab(1);
          }
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error.message);
        message.error("Something went wrong");
      }
    }
  };

  const handleProfieUpdate = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "/api/user/profileupdate",
        {
          mobile: form?.mobile,
          email: form?.email,
          fname: form?.fname,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
        navigate("/");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="container-fluid hero-container register-container">
        <div className="row text-center w-100">
          <div className="d-block m-auto col-12 col-sm-12 col-md-6 col-lg-6">
            {tab === 0 && (
              <div className="register-form">
                <h1>Login</h1>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.mobile}
                    name="mobile"
                    type="number"
                    className="form-control"
                    placeholder="Enter Registered Mobile Number"
                  />
                </div>
                {showOtpField && (
                  <div className="form-fields mb-3">
                    <input
                      onChange={handleChange}
                      value={form?.otp}
                      name="otp"
                      type="number"
                      className="form-control"
                      placeholder="Enter Verification OTP"
                    />
                  </div>
                )}
                {!showOtpField && (
                  <button onClick={sendMobileOtp} className="register-btn">
                    Send OTP
                    {loading && (
                      <div
                        className="ms-1 spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
                {showOtpField && (
                  <button onClick={handleSubmit} className="register-btn">
                    Verify OTP
                    {loading && (
                      <div
                        className="ms-1 spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
                <div className="forgot-pass d-flex justify-content-between">
                  {/* <h6 className="text-center my-2">
                  New Customer? <Link to="/register">Sign Up</Link>
                </h6> */}
                  {/* <h6 className="text-center my-2">
                  Forgot Password? <Link to="/forgot-password">Click Here</Link>
                </h6> */}
                </div>
              </div>
            )}

            {tab === 1 && (
              <div className="register-form">
                <h1>Update Profile</h1>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.fname}
                    name="fname"
                    type="text"
                    className="form-control"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="form-fields mb-3">
                  <input
                    onChange={handleChange}
                    value={form?.email}
                    name="email"
                    type="text"
                    className="form-control"
                    placeholder="Enter your email"
                  />
                </div>
                <button onClick={handleProfieUpdate} className="register-btn">
                  Update Profile
                  {loading && (
                    <div
                      className="ms-1 spinner-grow spinner-grow-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
